import ExamResponseDTO from '@/dtos/ExamResponseDTO';
import { SchedulingDemoDTO } from '@/dtos/SchedulingDemoDTO';
import UpdateCertificateGivenAtDTO from '@/dtos/UpdateCertificateGivenAtDTO';
import GestorExamDTO from '@/dtos/gestor/GestorExamDTO';
import ExamsQuery from '@/queryObjects/ExamsQuery';
import { Get, Patch, Post, Put } from './BaseService';
import ProctoringResponseDTO from '@/dtos/ProctoringResponseDTO';
import { ExamReportDTO } from '@/dtos/reports/ExamReportDTO';

export default {
    GetExam:(query: Partial<ExamsQuery>) => {
        return Get<any>(`api/Exam`,query);
    },

    GetExame: (examId: string) => {
        const exam = Get<ExamResponseDTO>(`api/Exam/${examId}/admin`);
        return exam;
    },

    GetExameReport: (examId: string) => {
        const exam = Get<ExamReportDTO>(`api/Exam/${examId}/report`);
        return exam;
    },

    GetExameGestor: (examId: string) => {
        const exam = Get<ExamResponseDTO>(`api/Exam/gestor/${examId}/admin`);
        return exam;
    },

    GetExameGestorBySchedulingId: (schedulingId: string) => {
        const exam = Get<GestorExamDTO>(`api/Exam/gestor/${schedulingId}`);
        return exam;
    },

    GetFinishedExamsToday: (schedulingLocal: string) => {
        return Get<GestorExamDTO[]>(`api/Exam/finished-today?schedulingLocal=${schedulingLocal}`);
    },

    UpdateCertificateGivenAt: (id: string, certificateGivenAt: string | null) => {
        return Patch<UpdateCertificateGivenAtDTO, any>(`api/Exam/update-certificate-given-at`, {
            id: id,
            certificateGivenAt: certificateGivenAt,
        });
    },

    GetTesteExameTeorico: () => {
        const exam = Get<SchedulingDemoDTO>(`api/Exam/generate-demo-exam `);
        return exam;
    },

    AuditAll: (id: string, data: any) => {
        return Post<any, any>(`api/Exam/release/${id}`, data);
    },

    AuditThenRelease: (id: string, data: any) => {
        return Post<any, any>(`api/Exam/audit-then-release/${id}`, data);
    },

    UpdateStatus: (examId: string, status: any) => {
        const request = Put<any, any>(`api/Exam/update-status/${examId}?status=${status}`, {});
        return request;
    },

    CreateScheduling: (SchedulingDemoDTO: SchedulingDemoDTO) => {
        return Post<SchedulingDemoDTO, SchedulingDemoDTO>(`api/Exam`, SchedulingDemoDTO);
    },

    UpdateExaminerExams: (cpf: string, examsIds: string[]) => {
        return Put<any, any>(`api/Exam/update-exams-viewer`, { cpf, examsIds });
    },

    GetAuditoriaByProctoringId: (proctoringId: string) => { return Get<ProctoringResponseDTO>(`/api/Exam/proctoring/${proctoringId}`); },
};
